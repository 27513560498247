$(document).ready(function () {

	initStickyHeader();
  initMatchHeight();
  initPracticeTabs();
  initMenuMobile();

});

function initStickyHeader() {
  var menu = document.querySelector('.header');
	var origOffsetY = menu.offsetTop;
	function scroll () {
		if ($(window).scrollTop() > origOffsetY) {
			$('.header').addClass('scrolling');
		} else {
			$('.header').removeClass('scrolling');
		}
	}
	scroll();
	document.onscroll = scroll;
}

function initMatchHeight() {
  $('.height').matchHeight();
}

function initPracticeTabs() {
	var tabs = $('.content__item');
	var triggers = $('.practice-page__tabs li');
	var container = $('.practice-page__wrapper');
	$(triggers).on('click', (e) => {
		e.preventDefault();
		var clickEl = e.target.closest('li') || e.target;
		var target = clickEl.getAttribute('data-target');
		switchBtn(clickEl);
		switchTab(target);
	});
	function switchBtn(clickEl) {
		var active = $('.practice-page__tabs li.active');
		$(active).removeClass('active');
		$(clickEl).addClass('active');
	}
	function switchTab(target) {
		var active = $('.content__item.active');
		var next = $('#' + target);
		$(container).css('height', $(active).outerHeight());
		$(active).fadeOut(250, function() {
			$(next).fadeIn(250).addClass('active');
			$(container).css('height', $(next).outerHeight());
		}).removeClass('active');
	}
}

function initMenuMobile() {
	var trigger = '.header__trigger';
	var menu = '.header__menu';
	$(trigger).on('click', function() {
		$(trigger).stop(true,true).toggleClass('menu-visible');
		$(menu).stop(true,true).toggleClass('menu-visible');
	});
}
